import { AxiosRequestConfig } from 'axios'

const getServicesOffered = (): AxiosRequestConfig => ({
  url: '/api/v1/services',
  method: 'GET'
})

const getServicesOfferedByModel = (modelId: string): AxiosRequestConfig => ({
  url: `/api/v1/users/${modelId}/services`,
  method: 'GET'
})

const getModelImages = (modelId: string): AxiosRequestConfig => ({
  url: `/api/v1/users/${modelId}/images`,
  method: 'GET'
})

export { getServicesOffered, getServicesOfferedByModel, getModelImages }
