import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { Box } from '@mui/material' // Agregar Box para usar el espaciador
import { useNavigate, useLocation } from 'react-router'

import { removeAuthHeader } from 'utils'
import { ReactComponent as Logo } from 'assets/images/app-logo.svg'
import { UserContext } from 'contexts'

import { useStyles } from './styles'

interface Props {
  isLoggedIn?: boolean
}

const NavBar: React.FC<Props> = ({ isLoggedIn }) => {
  const classes = useStyles()
  const { setAdmin } = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()

  const onLogout = () => {
    localStorage.clear()
    removeAuthHeader()
    setAdmin(undefined)
    navigate('/login', { state: { from: location } })
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Logo height={50} width={200} />
        <Box sx={{ flexGrow: 1 }} />
        {isLoggedIn && (
          <Button color="inherit" className={classes.logout} onClick={onLogout}>
            Cerrar sesión
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

NavBar.defaultProps = {
  isLoggedIn: false
}

export { NavBar }
