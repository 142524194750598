import React from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import { Slide, SlideProps } from '@mui/material'

interface SnackbarProps {
  message: React.ReactNode
  isOpen: boolean
  handleClose: () => void
}

const TransitionUp = (props: SlideProps) => {
  return <Slide {...props} direction="up" />
}

const Snackbar: React.FC<SnackbarProps> = ({ message, isOpen, handleClose }) => {
  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      message={message}
      TransitionComponent={TransitionUp}
    />
  )
}

export { Snackbar }
