type ChipColor = 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'

interface RoleLabel {
  label: string
  chipColor: ChipColor
}

const roleLabelsMap: Record<string, RoleLabel> = {
  admin: {
    label: 'Admin',
    chipColor: 'primary' as const
  },
  employee: {
    label: 'Empleado',
    chipColor: 'secondary' as const
  },
  guest: {
    label: 'Guest',
    chipColor: 'default' as const
  }
}

export { roleLabelsMap }
