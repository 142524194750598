import React from 'react'

import { snackbar } from 'components'
import { ERRORS } from 'consts'
import { useApi } from 'hooks'

import { DefaultTable } from '../components'
import { getServicesOffered } from './api'
import { GetServicesOfferedResponse, ServiceOffered } from './types'

const ServicesOffered: React.FC = () => {
  const [{ data: servicesOffered, isLoading }] = useApi<
    GetServicesOfferedResponse,
    ServiceOffered[]
  >(
    getServicesOffered,
    (responseData: GetServicesOfferedResponse) => responseData?.servicesOffered ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const columns = [
    { title: 'ID', field: 'id' },
    { title: 'Nombre', field: 'name' }
  ]

  return (
    <DefaultTable
      title="Servicios ofrecidos"
      columns={columns}
      data={servicesOffered}
      isLoading={isLoading}
    />
  )
}

export { ServicesOffered }
