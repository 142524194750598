const isEmail = (email: string): boolean => {
  // eslint-disable-next-line no-control-regex
  const emailRegex = /^[a-zA-Z0-9_.+]+(?<!^[0-9]*)@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  return emailRegex.test(email)
}

const isURL = (url: string): boolean => {
  const URLRegex =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
  return URLRegex.test(url)
}

export const StringUtils = {
  isEmail,
  isURL
}
