const GENERIC_ERROR_MESSAGE = 'Ocurrió un error. Intentalo de nuevo'
const NO_DATA_ERROR_MESSAGE = 'No hay datos que coincidan con tu solicitud.'
const LIMIT_ROWS_ERROR_MESSAGE =
  'El rango solicitado es demasiado grande. Inténtelo de nuevo o solicite el reporte al área de soporte.'

export const ERRORS = {
  GENERIC_ERROR_MESSAGE,
  NO_DATA_ERROR_MESSAGE,
  LIMIT_ROWS_ERROR_MESSAGE
}
