// import { makeStyles } from '@mui/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  overflow: {
    overflow: 'visible'
  },
  dialogContent: {
    padding: 24
  }
}))

export { useStyles }
