import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { Input, Select, MenuItem, Typography, Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import { Dialog, snackbar } from 'components'
import { useApi, useApiCall } from 'hooks'
import { ERRORS } from 'consts'
import {
  GetServicesOfferedByModelResponse,
  ServiceOffered
} from 'features/Main/ServicesOffered/types'
import { getServicesOfferedByModel } from 'features/Main/ServicesOffered/api'

import { User, UpdateUserResponse, UpdateModelServicesData } from '../../types'
import { updateUserServices } from '../../api'
import { useStyles } from './styles'

interface UpdateUserServicesOfferedDialogProps {
  handleClose: () => void
  userToUpdate: User
}

const UpdateUserServicesOfferedDialog: React.FC<UpdateUserServicesOfferedDialogProps> = ({
  handleClose,
  userToUpdate
}) => {
  const classes = useStyles()

  const [{ data: servicesOfferedByModel, isLoading }] = useApi<
    GetServicesOfferedByModelResponse,
    ServiceOffered[]
  >(
    () => getServicesOfferedByModel(userToUpdate.topic),
    (responseData: GetServicesOfferedByModelResponse) => responseData?.servicesOfferedByModel ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const [currentSelection, setCurrentSelection] = useState<ServiceOffered[]>([])

  useEffect(() => {
    if (servicesOfferedByModel) setCurrentSelection(servicesOfferedByModel)
  }, [servicesOfferedByModel])

  const [updateUserApi] = useApiCall<UpdateModelServicesData, UpdateUserResponse>(
    updateUserServices
  )

  const onUpdateUser = async () => {
    const modelServicesOffered = currentSelection
      .filter((service) => service.activeToUser)
      .map((service) => {
        return {
          serviceOfferedId: service.id,
          included: service.included || false,
          extraCharge: service.extraCharge || ''
        }
      })

    const payload = {
      userId: userToUpdate.topic,
      payload: modelServicesOffered
    }
    try {
      await updateUserApi(payload)
      handleClose()
      snackbar.show('Servicios actualizados')
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar modelo. Intente de nuevo.')
    }
  }

  const handleEnableOrDisableService = (serviceId: number) => {
    setCurrentSelection((prevSelection) => {
      return prevSelection.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            activeToUser: !service.activeToUser
          }
        }
        return service
      })
    })
  }

  const handleExtraChange = (serviceId: number, optionSelected: string) => {
    setCurrentSelection((prevSelection) => {
      return prevSelection.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            included: optionSelected === 'included'
          }
        }
        return service
      })
    })
  }

  const handleExtraChargeChange = (serviceId: number, extraCharge: string) => {
    setCurrentSelection((prevSelection) => {
      return prevSelection.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            extraCharge
          }
        }
        return service
      })
    })
  }

  return (
    <Dialog
      title="Servicios ofrecidos"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Guardar"
      okButtonProps={{ disabled: false }}
      onAccept={onUpdateUser}
      className={classes.dialog}
    >
      <Grid container spacing={2}>
        {currentSelection.map((service) => (
          <Grid item xs={6} key={service.id}>
            <Typography variant="h6">{service.name}</Typography>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" marginRight={1}>
                <Checkbox
                  checked={service.activeToUser}
                  onChange={() => handleEnableOrDisableService(service.id)}
                />
              </Box>
              <Box display="flex" alignItems="center" marginRight={1}>
                {service.activeToUser ? (
                  <Select
                    value={service.included ? 'included' : 'extra'}
                    onChange={(event) =>
                      handleExtraChange(
                        service.id,
                        event.target.value === 'included' ? 'included' : 'extra'
                      )
                    }
                  >
                    <MenuItem value="included">Incluido</MenuItem>
                    <MenuItem value="extra">Con cargo</MenuItem>
                  </Select>
                ) : null}
              </Box>
              {service.included || !service.activeToUser ? null : (
                <Input
                  value={service.extraCharge}
                  onChange={(event) => handleExtraChargeChange(service.id, event.target.value)}
                  placeholder="Ingrese el cargo adicional"
                  inputMode="numeric"
                />
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Dialog>
  )
}

export { UpdateUserServicesOfferedDialog }
