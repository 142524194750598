import { useState, useEffect, Dispatch, SetStateAction, useMemo, useCallback } from 'react'
import { AxiosRequestConfig } from 'axios'

import { api } from 'utils'
import { ApiError } from 'errors'
import { ErrorTypes } from 'types'

import { UseApiError, UseApiResponse, UseApiConfig } from './types'

function useApi<RES, DATA>(
  getConfig: (payload?: object) => AxiosRequestConfig,
  responseGetter: (response: RES) => DATA,
  config: UseApiConfig<DATA>
): [UseApiResponse<DATA>, () => void, Dispatch<SetStateAction<DATA>>] {
  const [data, setData] = useState<DATA>(config.baseData)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<UseApiError | undefined>(undefined)

  const requestConfig = useMemo(getConfig, config.deps ?? [])

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data: fetchedData } = await api.request<RES>(requestConfig)
      setData(responseGetter(fetchedData))
    } catch (err: unknown) {
      if (typeof err === 'object' && err !== null && 'response' in err) {
        const errorResponse = (err as { response: any }).response
        console.error(errorResponse)
        const newError = new ApiError(
          errorResponse.data.error.message,
          errorResponse.status,
          errorResponse.data.error.type
        )
        setError(newError)
        if (config.onError) config.onError(newError)
      } else {
        const errMessage = `Bad request or server didn't respond`
        console.error(errMessage)
        const newError = new ApiError(errMessage, 400, ErrorTypes.RequestError)
        setError(newError)
        if (config.onError) config.onError(newError)
      }
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestConfig])

  useEffect(() => {
    fetch()
  }, [fetch])

  return [{ data, isLoading, error }, fetch, setData]
}

function useApiCall<REQ_DATA, RES>(
  apiCall: (data: REQ_DATA) => AxiosRequestConfig
): [(data: REQ_DATA) => Promise<RES>, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetch = useCallback(
    async (fetchData: REQ_DATA) => {
      setIsLoading(true)
      try {
        const { data: fetchedData } = await api.request<RES>(apiCall(fetchData))
        return fetchedData
      } catch (err) {
        if (err instanceof Error && (err as any).response) {
          const { response } = err as any
          console.error(response)
          throw new ApiError(
            response.data.error.message,
            response.status,
            response.data.error.type,
            response.data.error.validation?.keys
          )
        } else {
          const errMessage = `Bad request or server didn't respond`
          console.error(errMessage)
          throw new ApiError(errMessage, 400, ErrorTypes.RequestError)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [apiCall]
  )

  return [fetch, isLoading]
}

export { useApi, useApiCall }
