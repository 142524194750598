import React, { useState, useEffect } from 'react'
import { Typography, Box, TextField, MenuItem, Select } from '@mui/material'

import { Dialog, snackbar } from 'components'
import { useApi, useApiCall } from 'hooks'
import { ERRORS } from 'consts'
import { attributeOptions } from 'types'
import { useStyles } from './styles'
import {
  ProfileAttributes,
  UpdateProfileAttributeData,
  User,
  UserProfileAttributesResponse
} from '../../types'
import { getProfileAttributesByModel, updateOrCreateModelProfileAttributes } from '../../api'

interface UpdateUserProfileAttributesDialogProps {
  handleClose: () => void
  userToUpdate: User
}

const UpdateUserProfileAttributesDialog: React.FC<UpdateUserProfileAttributesDialogProps> = ({
  handleClose,
  userToUpdate
}) => {
  const classes = useStyles()

  const [{ data: profileAttributesByModel, isLoading }] = useApi<
    UserProfileAttributesResponse,
    ProfileAttributes[]
  >(
    () => getProfileAttributesByModel(userToUpdate.id),
    (responseData: UserProfileAttributesResponse) => responseData?.profileAttributesByModel ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const [updateUserApi] = useApiCall<UpdateProfileAttributeData, void>(
    updateOrCreateModelProfileAttributes
  )

  const [currentSelection, setCurrentSelection] = useState<ProfileAttributes[]>([])

  useEffect(() => {
    if (profileAttributesByModel) setCurrentSelection(profileAttributesByModel)
  }, [profileAttributesByModel])

  const onUpdateUser = async () => {
    const modelProfileAttributes = currentSelection
      .filter((profileAttribute) => profileAttribute.value.length)
      .map((profileAttribute) => {
        return {
          profileAttributeId: profileAttribute.id,
          value: profileAttribute.value || ''
        }
      })

    const payload = {
      userId: userToUpdate.id,
      payload: modelProfileAttributes
    }
    try {
      await updateUserApi(payload)
      handleClose()
      snackbar.show('Perfil de modelo actualizado')
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar el perfil de modelo. Intente de nuevo.')
    }
  }

  const handleChangeValue = (profileAttributeId: number, value: string) => {
    setCurrentSelection((prevSelection) => {
      return prevSelection.map((profileAttribute) => {
        if (profileAttribute.id === profileAttributeId) {
          return {
            ...profileAttribute,
            value
          }
        }
        return profileAttribute
      })
    })
  }

  return (
    <Dialog
      title={`Atributos de modelo ${userToUpdate.fullname}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Guardar"
      okButtonProps={{ disabled: false }}
      onAccept={onUpdateUser}
      className={classes.dialog}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px'
        }}
      >
        {currentSelection.map((profileAttribute) => (
          <Box
            key={profileAttribute.id}
            sx={{
              flexBasis: { xs: '100%', sm: '48%' },
              flexGrow: 1
            }}
          >
            <Typography variant="h6">{profileAttribute.name}</Typography>
            <Box display="flex" alignItems="center">
              {attributeOptions[profileAttribute.name.toLowerCase()] ? (
                <Select
                  fullWidth
                  value={profileAttribute.value}
                  onChange={(e) => handleChangeValue(profileAttribute.id, e.target.value)}
                >
                  {attributeOptions[profileAttribute.name.toLowerCase()].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  fullWidth
                  disabled={false}
                  onChange={(e) => handleChangeValue(profileAttribute.id, e.target.value)}
                  type="text"
                  value={profileAttribute.value}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}

export { UpdateUserProfileAttributesDialog }
