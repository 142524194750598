import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { Layout } from 'components'
import { UserContext } from 'contexts'

import { Users } from './Users'
import { Countries } from './Countries'
import { Admins } from './Admins'
import { ServicesOffered } from './ServicesOffered'
import { ModelImages } from './Users/Images'

const NotFound = () => <h1>404 no encontrado</h1> // Mueve el componente fuera del componente Main

const Main: React.FC = () => {
  const { admin } = useContext(UserContext)
  if (!admin) return <Navigate to="/login" />

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Users />} />
        <Route path="/models" element={<Users />} />
        <Route path="/models/images" element={<ModelImages />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/services" element={<ServicesOffered />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  )
}

export { Main }
