import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Chip } from '@mui/material'

import { Dialog, snackbar } from 'components'
import { useApi, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'

import { deleteAdmin, getAdmins, getRoles } from './api'
import { NewAdminDialog, UpdateAdminDialog } from './components'
import { DefaultTable } from '../components'
import { Admin, GetAdminsResponse, GetRolesResponse, Role } from './types'
import { roleLabelsMap } from './constants'

const validChipColors = ['default', 'primary', 'secondary', 'success', 'error', 'info', 'warning']

const columns = [
  { title: 'ID', field: 'id' },
  { title: 'Email', field: 'email' },
  {
    title: 'Roles',
    render: (admin: Admin) =>
      admin.roles.length
        ? admin.roles.map((role) => (
            <Box key={role.id} mr={1} display="inline-block">
              <Chip
                size="small"
                label={roleLabelsMap[role.name].label}
                color={
                  validChipColors.includes(roleLabelsMap[role.name].chipColor)
                    ? roleLabelsMap[role.name].chipColor
                    : 'default'
                }
              />
            </Box>
          ))
        : STRINGS.TABLE_COLUMN_NOT_DEFINED,
    sorting: false
  }
]

const ConfirmDeletionDialog: React.FC<{
  isOpen: boolean
  onCancel: () => void
  title: string
  isLoading: boolean
  onAccept: () => void
  okButtonText: string
  okButtonProps: { disabled: boolean }
}> = ({ isOpen, onCancel, title, isLoading, onAccept, okButtonText, okButtonProps }) => (
  <Dialog
    isOpen={isOpen}
    showActions
    onCancel={onCancel}
    title={title}
    isLoading={isLoading}
    onAccept={onAccept}
    okButtonText={okButtonText}
    okButtonProps={okButtonProps}
  />
)

const Admins: React.FC = () => {
  const [{ data: admins, isLoading: isGetAdminsLoading }, fetchAdmins] = useApi<
    GetAdminsResponse,
    Admin[]
  >(getAdmins, (responseData) => responseData?.admins ?? [], {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
  })

  const [{ data: roles, isLoading: isGetRolesLoading }] = useApi<GetRolesResponse, Role[]>(
    getRoles,
    (responseData) => responseData?.roles ?? [],
    {
      baseData: [],
      onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  )

  const [deleteAdminApi, isDeleteAdminLoading] = useApiCall<number, void>(deleteAdmin)
  const [isConfirmDeletionDialogOpen, setIsConfirmDeletionDialogOpen] = useState(false)
  const [isNewAdminDialogOpen, setIsNewAdminDialogOpen] = useState(false)
  const [adminToDelete, setAdminToDelete] = useState<Admin | null>(null)
  const [isUpdateAdminDialogOpen, setIsUpdateAdminDialogOpen] = useState(false)
  const [adminToUpdate, setAdminToUpdate] = useState<Admin | null>(null)

  const onCreateAdminDone = () => {
    fetchAdmins()
    setIsNewAdminDialogOpen(false)
  }

  const handleConfirmationDialogClose = () => {
    setIsConfirmDeletionDialogOpen(false)
    setAdminToDelete(null)
  }

  const handleDeleteAdmin = async () => {
    if (!adminToDelete) return
    const { id } = adminToDelete
    try {
      await deleteAdminApi(id)
      fetchAdmins()
      handleConfirmationDialogClose()
    } catch (err) {
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleUpdateAdminDialogClose = () => {
    setIsUpdateAdminDialogOpen(false)
    setAdminToUpdate(null)
  }

  const onUpdateAdminDone = () => {
    fetchAdmins()
    handleUpdateAdminDialogClose()
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nuevo admin',
      isFreeAction: true,
      onClick: () => setIsNewAdminDialogOpen(true)
    },
    {
      icon: () => <EditIcon />,
      tooltip: `Editar admin`,
      onClick: (_: React.SyntheticEvent, row: Admin | Admin[]) => {
        setAdminToUpdate(row as Admin)
        setIsUpdateAdminDialogOpen(true)
      }
    },
    {
      icon: 'delete',
      tooltip: `Eliminar admin`,
      onClick: (_: React.SyntheticEvent, row: Admin | Admin[]) => {
        setIsConfirmDeletionDialogOpen(true)
        setAdminToDelete(row as Admin)
      }
    }
  ]

  return (
    <>
      <DefaultTable<Admin>
        title="Admins"
        columns={columns}
        data={admins}
        actions={actions}
        isLoading={isGetAdminsLoading || isGetRolesLoading}
      />
      {isNewAdminDialogOpen && (
        <NewAdminDialog
          handleClose={() => setIsNewAdminDialogOpen(false)}
          onDone={onCreateAdminDone}
          roles={roles}
        />
      )}
      {isConfirmDeletionDialogOpen && adminToDelete && (
        <ConfirmDeletionDialog
          isOpen
          onCancel={handleConfirmationDialogClose}
          title={`¿Está seguro que desea eliminar a ${adminToDelete.email}?`}
          isLoading={isDeleteAdminLoading}
          onAccept={handleDeleteAdmin}
          okButtonText="Eliminar"
          okButtonProps={{ disabled: isDeleteAdminLoading }}
        />
      )}
      {isUpdateAdminDialogOpen && adminToUpdate && (
        <UpdateAdminDialog
          admin={adminToUpdate}
          handleClose={handleUpdateAdminDialogClose}
          onDone={onUpdateAdminDone}
          roles={roles}
        />
      )}
    </>
  )
}

export { Admins }
