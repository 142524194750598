import React, { useState, useEffect } from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputLabel,
  SelectChangeEvent
} from '@mui/material'

import { Dialog, snackbar } from 'components'
import { useApi, useApiCall } from 'hooks'
import { ERRORS } from 'consts'

import {
  User,
  UpdateUserResponse,
  UpdateModelContactData,
  GetCountriesWithCitiesResponse,
  Country,
  City
} from '../../types'
import { getCountriesWithCities, updateModelContact } from '../../api'
import { useStyles } from './styles'

interface UpdateModelContactDialogProps {
  handleClose: () => void
  userToUpdate: User
}

const UpdateModelContactDialog: React.FC<UpdateModelContactDialogProps> = ({
  handleClose,
  userToUpdate
}) => {
  const classes = useStyles()

  const [phone, setPhone] = useState(userToUpdate.contact?.phone || '')
  const [countryId, setCountryId] = useState<number>(userToUpdate.contact?.countryId || 0)
  const [cityId, setCityId] = useState(userToUpdate.contact?.cityId || 0)
  const [cities, setCities] = useState<City[]>([])
  const [updateUserApi, isLoading] = useApiCall<UpdateModelContactData, UpdateUserResponse>(
    updateModelContact
  )

  const getCountriesWithCitiesResponseGetter = (responseData: GetCountriesWithCitiesResponse) =>
    responseData?.countries ?? []
  const [{ data: countries }] = useApi<GetCountriesWithCitiesResponse, Country[]>(
    getCountriesWithCities,
    getCountriesWithCitiesResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const handleSetCountry = (id: number) => {
    setCountryId(id as number)
    const selectedCountry = countries.find((country) => country.id === id)
    setCities(selectedCountry?.cities || [])
  }

  useEffect(() => {
    if (countries.length === 0) return
    handleSetCountry(countryId)
  }, [countries])

  const handlePhoneChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPhone(e.target.value)
  }

  const onUpdateUser = async () => {
    const userContactPayload = {
      phone,
      countryId,
      cityId
    }
    const payload = {
      id: userToUpdate.id,
      payload: userContactPayload
    }
    try {
      await updateUserApi(payload)
      handleClose()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar modelo. Intente de nuevo.')
    }
  }

  const handleCountryChange = (event: SelectChangeEvent<number>) => {
    const selectedCountryId = Number(event.target.value)
    if (!selectedCountryId) return
    handleSetCountry(selectedCountryId)
  }

  const handleCityChange = (event: SelectChangeEvent<number>) => {
    const selectedCity = Number(event.target.value)
    if (!selectedCity) return
    setCityId(selectedCity)
  }

  return (
    <Dialog
      title={`Editar contacto de ${userToUpdate.fullname}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: false }}
      onAccept={onUpdateUser}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Telefono"
            value={phone}
            fullWidth
            onChange={handlePhoneChange}
            disabled={isLoading}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.multiselect}>Pais</InputLabel>
          <FormControl className={classes.fullWidth}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-country"
              value={countryId || 0}
              onChange={handleCountryChange}
              disabled={false}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {cities.length > 0 && (
          <Grid item xs={12}>
            <InputLabel className={classes.multiselect}>Ciudad</InputLabel>
            <FormControl className={classes.fullWidth}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-city"
                value={cityId || 0}
                onChange={handleCityChange}
                disabled={false}
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export { UpdateModelContactDialog }
