import React, { CSSProperties, ReactNode } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MUIDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button, { ButtonProps } from '@mui/material/Button'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import { useStyles } from './styles'

interface DialogProps {
  isOpen: boolean
  title: string
  contentStyle?: CSSProperties
  style?: CSSProperties
  className?: string
  contentClassName?: string
  showActions?: boolean
  okButtonText?: string
  okButtonProps?: ButtonProps
  cancelButtonText?: string
  onAccept?: () => void
  onCancel: () => void
  isLoading?: boolean
  overflow?: boolean
  readOnly?: boolean
  children?: ReactNode
}

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onCancel,
  title,
  children,
  showActions = false,
  okButtonText,
  okButtonProps,
  cancelButtonText,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAccept = () => {},
  isLoading,
  contentStyle,
  style,
  className,
  contentClassName,
  overflow,
  readOnly
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const paperClassName = overflow ? classes.overflow : ''
  return (
    <MUIDialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      PaperProps={{ className: `${paperClassName} ${className}`, style }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers
        style={contentStyle}
        className={`${classes.overflow} ${classes.dialogContent} ${contentClassName}`}
      >
        <>
          {children}
          {isLoading && (
            <Box mt={4}>
              <LinearProgress />
            </Box>
          )}
        </>
      </DialogContent>
      {showActions && (
        <DialogActions>
          <Button onClick={onCancel}>
            {cancelButtonText ?? (readOnly ? 'Cerrar' : 'Cancelar')}
          </Button>
          {!readOnly && (
            <Button
              onClick={onAccept}
              variant="contained"
              color="primary"
              autoFocus
              {...okButtonProps}
            >
              {okButtonText ?? 'Aceptar'}
            </Button>
          )}
        </DialogActions>
      )}
    </MUIDialog>
  )
}

export { Dialog }
