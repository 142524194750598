export enum GenderEnum {
  Female = 'F',
  Male = 'M'
}

export const attributeOptions: Record<string, Array<{ value: string; label: string }>> = {
  gender: [
    { value: 'female', label: 'Female' },
    { value: 'trans_sexual', label: 'Trans Sexual' },
    { value: 'trans_gender', label: 'Trans Gender' }
  ],
  eyes: [
    { value: 'black', label: 'Black' },
    { value: 'blue', label: 'Blue' },
    { value: 'brown', label: 'Brown' },
    { value: 'green', label: 'Green' },
    { value: 'hazel', label: 'Hazel' }
  ],
  hairColor: [
    { value: 'black', label: 'Black' },
    { value: 'blonde', label: 'Blonde' },
    { value: 'brown', label: 'Brown' },
    { value: 'red', label: 'Red' }
  ],
  hairLength: [
    { value: 'short', label: 'Short' },
    { value: 'medium', label: 'Medium' },
    { value: 'long', label: 'Long' }
  ],
  breastSize: [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' }
  ],
  breastType: [
    { value: 'natural', label: 'Natural' },
    { value: 'silicone', label: 'Silicone' }
  ],
  pubicHair: [
    { value: 'natural', label: 'Natural' },
    { value: 'shaved', label: 'Shaved' }
  ],
  travel: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ],
  ethnicity: [
    { value: 'arabian', label: 'Arabian' },
    { value: 'asian', label: 'Asian' },
    { value: 'caucasian', label: 'Caucasian' },
    { value: 'ebony', label: 'Ebony' },
    { value: 'latin', label: 'Latin' },
    { value: 'mixed', label: 'Mixed' }
  ],
  orientation: [
    { value: 'bisexual', label: 'Bisexual' },
    { value: 'heterosexual', label: 'Heterosexual' },
    { value: 'homosexual', label: 'Homosexual' }
  ],
  smoker: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ],
  tatoo: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ],
  piercing: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ]
}
