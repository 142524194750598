import { useEffect, useState } from 'react'

import { Errors, Rules, Rule } from './types'

const useCheckFormErrors = <D extends object>(
  data: D,
  rules: Rules<D>,
  requiredField?: string[]
) => {
  const [errors, setErrors] = useState<Errors | null>(null)
  const [hasErrors, setHasErrors] = useState(false)
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(false)
  const initialErrors: Errors = {}
  if (errors === null) {
    Object.keys(rules).forEach((fieldName) => {
      initialErrors[fieldName] = {
        hasError: false,
        message: ''
      }
    })
  }

  useEffect(() => {
    const newErrors: Errors = {}
    let anyFieldEmpty = false
    let containsErrors = false
    Object.entries(rules).forEach(([fieldName, fieldRules]) => {
      newErrors[fieldName] = {
        hasError: false,
        message: ''
      }

      if (fieldName in data) {
        const fieldValue = data[fieldName as keyof D]

        if (requiredField?.length && requiredField.includes(fieldName) && !fieldValue) {
          anyFieldEmpty = true
          return
        }
        if (!requiredField?.length && !fieldValue) {
          anyFieldEmpty = true
          return
        }

        const rulesArray = fieldRules as Rule[]

        rulesArray.forEach((rule) => {
          const isValid = rule.validate(fieldValue, data)
          if (!isValid) {
            containsErrors = true
            newErrors[fieldName].hasError = true
            newErrors[fieldName].message = rule.message
          }
        })
      } else throw new Error(`Field "${fieldName}" (from rules) does not exist in data.`)
    })
    setErrors(newErrors)
    setIsAnyFieldEmpty(anyFieldEmpty)
    setHasErrors(containsErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(data)])
  return { errors: errors || initialErrors, isAnyFieldEmpty, hasErrors }
}

export { useCheckFormErrors }
