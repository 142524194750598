import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { Dialog, snackbar } from 'components'
import { useApi, useApiCall } from 'hooks'
import { ERRORS } from 'consts'
import { getModelImages } from 'features/Main/ServicesOffered/api'

import { GetImagesByModelResponse, ModelImage } from '../types'
import { DefaultTable } from '../../components'
import { CreateModelImageDialog, UpdateModelImageDialog } from '../components'
import { deleteUserImage } from './api'

interface LocationState {
  userId: string
  fullname: string
}

const ModelImages: React.FC = () => {
  const location = useLocation()
  const { userId, fullname } = location.state as LocationState
  const [selectedRowData, setSelectedRowData] = useState<ModelImage | null>(null)
  const [isCreateModelImageDialogOpen, setIsCreateModelImageDialogOpen] = useState(false)
  const [isEditModelImageDialogOpen, setIsEditModelImageDialogOpen] = useState(false)
  const [{ data: modelImages, isLoading }] = useApi<GetImagesByModelResponse, ModelImage[]>(
    () => getModelImages(userId),
    (responseData: GetImagesByModelResponse) => responseData?.modelImages.images ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const [deleteUserImageApi, isDeleteAdminLoading] = useApiCall<number, void>(deleteUserImage)
  const [isConfirmDeletionDialogOpen, setIsConfirmDeletionionDialogOpen] = useState(false)

  const handleCreateModelImageClose = () => {
    setIsCreateModelImageDialogOpen(false)
    window.location.reload()
  }

  const handleEditModelImageClose = () => {
    setIsEditModelImageDialogOpen(false)
    window.location.reload()
  }

  const handleConfirmationDialogClose = () => {
    setIsConfirmDeletionionDialogOpen(false)
    setSelectedRowData(null)
  }

  const handleDeleteImage = async () => {
    try {
      if (!selectedRowData) return
      const { id } = selectedRowData
      await deleteUserImageApi(id)
      handleConfirmationDialogClose()
    } catch (err) {
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    } finally {
      window.location.reload()
    }
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nuevo usuario',
      isFreeAction: true,
      onClick: () => setIsCreateModelImageDialogOpen(true)
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar Imagen',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, modelImage: ModelImage | ModelImage[]) => {
        setSelectedRowData(modelImage as ModelImage)
        setIsEditModelImageDialogOpen(true)
      }
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Eliminar Imagen',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, modelImage: ModelImage | ModelImage[]) => {
        setSelectedRowData(modelImage as ModelImage)
        setIsConfirmDeletionionDialogOpen(true)
      }
    }
  ]

  const columns = [
    {
      title: 'Imagen principal',
      render: (rowData: ModelImage) => (rowData.isMain ? 'Sí' : 'No'),
      sorting: false
    },
    {
      title: 'Mostrar en perfil',
      render: (rowData: ModelImage) => (rowData.show ? 'Sí' : 'No'),
      sorting: false
    },
    {
      title: 'Imagen',
      render: (rowData: ModelImage) => <img src={rowData.imageUrl} alt="" style={{ width: 100 }} />,
      sorting: false
    }
  ]

  return (
    <>
      <DefaultTable
        title={`Imagenes de ${fullname}`}
        columns={columns}
        data={modelImages}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateModelImageDialogOpen && (
        <CreateModelImageDialog
          handleClose={() => setIsCreateModelImageDialogOpen(false)}
          userId={userId}
          onDone={handleCreateModelImageClose}
        />
      )}
      {isEditModelImageDialogOpen && selectedRowData && (
        <UpdateModelImageDialog
          handleClose={() => setIsEditModelImageDialogOpen(false)}
          rowData={selectedRowData} // Pass the selected rowData object
          onDone={handleEditModelImageClose}
        />
      )}
      {isConfirmDeletionDialogOpen && selectedRowData && (
        <Dialog
          isOpen
          showActions
          onCancel={handleConfirmationDialogClose}
          title="¿Está seguro que desea la imagen?"
          isLoading={isDeleteAdminLoading}
          onAccept={handleDeleteImage}
          okButtonText="Eliminar"
          okButtonProps={{ disabled: isDeleteAdminLoading }}
        />
      )}
    </>
  )
}

export { ModelImages }
