import React, { CSSProperties, useState, useRef } from 'react'
import { Button } from '@mui/material'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { useApiCall } from 'hooks'
import { Dialog } from 'components'

import { CreateImageData, UpdateUserResponse } from '../../types'
import { useStyles } from './styles'
import canvasPreview from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'

import { createModelImage } from '../../api'

interface CreateModelImageDialogProps {
  handleClose: () => void
  userId: string
  onDone: () => void
}

const ASPECT_RATIO = 484 / 700

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90, // Adjust this value as needed for the initial width of the crop area.
        height: 90 * (1 / aspect) // Calculate the height based on the aspect ratio (width / height).
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const CreateModelImageDialog: React.FC<CreateModelImageDialogProps> = ({
  handleClose,
  userId,
  onDone
}) => {
  const classes = useStyles()

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  // const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale] = useState(1)
  const [rotate] = useState(0)
  const [loading, setLoading] = useState(false)
  const [aspect] = useState<number | undefined>(ASPECT_RATIO)

  const onSelectFile = (file: File | undefined) => {
    if (file) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
      })
      reader.readAsDataURL(file)
    }
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    onSelectFile(file)
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const generateBlobUrl = (canvasRef: React.RefObject<HTMLCanvasElement>): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (!canvasRef.current) {
        reject(new Error('Crop canvas does not exist'))
        return
      }
      canvasRef.current.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob'))
          return
        }
        resolve(blob)
      })
    })
  }
  // const onDownloadCropClick = async () => {
  //   const blob = await generateBlobUrl(previewCanvasRef)
  //   const blobUrl = URL.createObjectURL(blob)
  //   hiddenAnchorRef.current!.href = blobUrl
  //   hiddenAnchorRef.current!.click()
  // }
  const [createModelImageApi] = useApiCall<CreateImageData, UpdateUserResponse>(createModelImage)
  const onCreateImage = async () => {
    try {
      setLoading(true)
      const blob = await generateBlobUrl(previewCanvasRef)
      const fileName = `newImage.png`
      const file = new File([blob], fileName, { type: 'image/png' })
      const payload = {
        id: userId,
        image: file
      }
      await createModelImageApi(payload)
      onDone()
    } finally {
      setLoading(false)
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const dialogStyle: CSSProperties = {
    width: '600px',
    height: '900px'
  }

  return (
    <Dialog
      title="Imagenes"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={loading}
      okButtonText="Guardar"
      okButtonProps={{ disabled: loading }}
      className={classes.dialog}
      onAccept={onCreateImage}
      style={dialogStyle}
    >
      <div className="App">
        <div className="Crop-Controls">
          {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button variant="contained" color="primary" onClick={handleButtonClick}>
            Seleccionar archivo
          </Button>
        </div>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <canvas
          ref={previewCanvasRef}
          style={{
            border: '1px solid black',
            objectFit: 'contain',
            display: 'none'
          }}
        />
        {/* 
        {!!completedCrop && (
          <>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <div>
              <button onClick={onDownloadCropClick}>Descargar muestra</button>
              <a
                ref={hiddenAnchorRef}
                download
                style={{
                  position: 'absolute',
                  top: '-200vh',
                  visibility: 'hidden',
                }}
              >
                Hidden download
              </a>
            </div>
          </>
        )} */}
      </div>
    </Dialog>
  )
}

export { CreateModelImageDialog }
