import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import moment, { Moment } from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import { MenuItem, Checkbox, Select, SelectChangeEvent } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Dialog, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'
import { STRINGS } from 'consts'

import { User, UpdateUserResponse, UpdateUserData } from '../../types'
import { updateUser } from '../../api'

const rules = {
  fullname: [
    {
      validate: (value: string) => value.length <= 100,
      message: 'No puede superar los 100 caracteres'
    }
  ],
  email: [
    {
      validate: (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return value.length <= 254 && emailRegex.test(value)
      },
      message: 'Ingrese un correo electrónico válido'
    }
  ],
  birthdate: [
    {
      validate: (value: Moment | null) => {
        if (!value) {
          return false
        }
        return true
      },
      message: 'La fecha de nacimiento debe ser mayor a 18 años y menor a 60 años'
    }
  ]
}

const requiredFields = ['fullname', 'email']

interface UpdateUserDialogProps {
  handleClose: () => void
  userToUpdate: User
  onDone: (newUser: User) => void
}

const UpdateUserDialog: React.FC<UpdateUserDialogProps> = ({
  handleClose,
  onDone,
  userToUpdate
}) => {
  const [fullname, setFullname] = useState(userToUpdate.fullname)
  const [email, setEmail] = useState(userToUpdate.email ?? '')
  const [available, setAvailable] = useState(userToUpdate.available ?? false)
  const [status, setStatus] = useState(userToUpdate.status ?? '')
  const [membership, setMembership] = useState<string | undefined>(
    userToUpdate.membership ?? undefined
  )
  const [birthdate, setBirthdate] = useState<Moment | null>(moment(userToUpdate.birthdate))
  const [verified, setVerified] = useState(userToUpdate.verified)
  const [richDescription, setRichDescription] = useState(userToUpdate.richDescription ?? '')
  const [shortDescription, setShortDescription] = useState(userToUpdate.shortDescription ?? '')

  const [updateUserApi, isLoading] = useApiCall<UpdateUserData, UpdateUserResponse>(updateUser)

  const handleVerifiedChange: React.ChangeEventHandler<HTMLInputElement> = async () => {
    await setVerified(!verified)
  }

  const handleAvailableChange: React.ChangeEventHandler<HTMLInputElement> = async () => {
    await setAvailable(!available)
  }

  const handleFullnameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFullname(e.target.value)
  }

  const handleShortDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setShortDescription(e.target.value)
  }

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setBirthdate(newDate)
  }

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value as string)
  }

  const handleMembershipChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target
    const newValue = value === 'false' ? undefined : value
    setMembership(newValue)
  }

  const fields = { fullname, email, birthdate }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onUpdateUser = async () => {
    const user = {
      fullname,
      email,
      birthdate: birthdate?.toISOString(),
      available,
      verified,
      shortDescription,
      richDescription,
      status,
      membership
    }
    const payload = {
      id: userToUpdate.id,
      payload: user
    }
    try {
      const { user: updatedUser } = await updateUserApi(payload)
      onDone(updatedUser)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar modelo. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Editar modelo"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateUser}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Nombre fantasia"
            value={fullname}
            fullWidth
            onChange={handleFullnameChange}
            disabled={isLoading}
            required
            error={errors.fullname.hasError}
            helperText={errors.fullname.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={email}
            fullWidth
            onChange={handleEmailChange}
            disabled={isLoading}
            required
            error={errors.email.hasError}
            helperText={errors.email.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={birthdate}
            onChange={handleDateChange}
            minDate={moment().subtract(70, 'years')}
            maxDate={moment().subtract(18, 'years')}
            format={STRINGS.DATE_FORMAT}
            // initialFocusedDate={moment().subtract(18, 'years')}
            disabled={isLoading}
            // style={{ width: '100%' }}
            label="Cumpleaños "
            // minDateMessage="El usuario debe ser menor de 70 años"
            // maxDateMessage="El usuario debe ser mayor de 18 años"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripcion Corta"
            value={shortDescription}
            fullWidth
            onChange={handleShortDescriptionChange}
            error={errors.email.hasError}
            helperText={errors.email.message}
          />
        </Grid>
        <Grid item xs={11}>
          <label id="htmlContent-label">Descripcion larga</label>
          <div aria-labelledby="htmlContent-label">
            <ReactQuill value={richDescription} onChange={setRichDescription} />
          </div>
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleVerifiedChange} checked={verified} />}
            label="Usuario verificado?"
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleAvailableChange} checked={available} />}
            label="Disponible"
          />
        </Grid>
        <Grid item xs={6}>
          <h3>Estado: </h3>
          <Select value={status} onChange={handleStatusChange}>
            <MenuItem value="active">Activo</MenuItem>
            <MenuItem value="hidden">Oculto</MenuItem>
            <MenuItem value="blocked">Bloqueado</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <h3>Membresia: </h3>
          <Select value={membership} onChange={handleMembershipChange}>
            <MenuItem value="false">ninguna</MenuItem>
            <MenuItem value="VIP">VIP</MenuItem>
            <MenuItem value="TOP">TOP</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateUserDialog }
