import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: 'rgb(245, 246, 248)',
    minHeight: '100vh'
  },
  toolbar: theme.mixins.toolbar
}))

export { useStyles }
