import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import LocationCityIcon from '@mui/icons-material/LocationCity'

import { snackbar } from 'components'
import { ERRORS } from 'consts'
import { useApi } from 'hooks'

import { UpdateCountryCitiesDialog, UpdateCountryDialog } from './components'
import { DefaultTable } from '../components'
import { getCountries } from './api'
import { GetCountriesResponse, Country } from './types'

const Countries: React.FC = () => {
  const [{ data: countries, isLoading }, , setCountries] = useApi<GetCountriesResponse, Country[]>(
    getCountries,
    (responseData: GetCountriesResponse) => responseData?.countries ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )
  const [isUpdateCountryDialogOpen, setIsUpdateCountryDialogOpen] = useState(false)
  const [isUpdateCountryCitiesDialogOpen, setIsUpdateCountryCitiesDialogOpen] = useState(false)
  const [countryToUpdate, setCountryToUpdate] = useState<Country | null>(null)

  const columns = [
    { title: 'ID', field: 'id' },
    { title: 'Nombre', field: 'name' },
    {
      title: 'Activo para web',
      render: (rowData: Country) => (rowData.active ? 'Sí' : 'No'),
      customSort: (a: Country, b: Country) => {
        if (a.active === b.active) return 0
        return a.active ? -1 : 1
      },
      sorting: true
    },
    {
      title: 'Mostrar en backoffice',
      render: (rowData: Country) => (rowData.enabled ? 'Sí' : 'No'),
      sorting: false
    }
  ]

  const actions = [
    {
      icon: () => <EditIcon />, // Usar una función que retorna el ícono
      tooltip: 'Editar país',
      onClick: (_: React.SyntheticEvent, row: Country | Country[]) => {
        setCountryToUpdate(row as Country)
        setIsUpdateCountryDialogOpen(true)
      }
    },
    {
      icon: () => <LocationCityIcon />, // Usar una función que retorna el ícono
      tooltip: 'Ciudades',
      onClick: (_: React.SyntheticEvent, row: Country | Country[]) => {
        setCountryToUpdate(row as Country)
        setIsUpdateCountryCitiesDialogOpen(true)
      }
    }
  ]

  const handleUpdateCountryDialogClose = () => {
    setIsUpdateCountryDialogOpen(false)
    setCountryToUpdate(null)
    window.location.reload()
  }

  const handleUpdateCountryDone = (updatedCountry: Country) => {
    setCountries(
      countries.map((country) => {
        if (country.id === updatedCountry.id) {
          return updatedCountry
        }
        return country
      })
    )
    handleUpdateCountryDialogClose()
  }

  return (
    <>
      <DefaultTable
        title="Paises"
        columns={columns}
        data={countries}
        isLoading={isLoading}
        actions={actions}
      />
      {isUpdateCountryDialogOpen && countryToUpdate && (
        <UpdateCountryDialog
          handleClose={handleUpdateCountryDialogClose}
          countryToUpdate={countryToUpdate}
          onDone={handleUpdateCountryDone}
        />
      )}
      {isUpdateCountryCitiesDialogOpen && countryToUpdate && (
        <UpdateCountryCitiesDialog
          handleClose={handleUpdateCountryDialogClose}
          countryToUpdate={countryToUpdate}
          onDone={handleUpdateCountryDone}
        />
      )}
    </>
  )
}

export { Countries }
