import { AxiosRequestConfig } from 'axios'

import {
  CreateImageData,
  CreateUserData,
  UpdateModelContactData,
  UpdateModelImageAttributesData,
  UpdateModelServicesData,
  UpdateProfileAttributeData,
  UpdateUserData
} from './types'

const getUsers = (): AxiosRequestConfig => ({
  url: '/api/v1/models',
  method: 'GET'
})

const getUserPollResult = (userId: number): AxiosRequestConfig => ({
  url: `/users/${userId}/poll-result`,
  method: 'GET'
})

const updateUser = (data: UpdateUserData): AxiosRequestConfig => {
  const payload = new FormData()
  const {
    status,
    fullname,
    email,
    birthdate,
    gender,
    image,
    verified,
    available,
    shortDescription,
    richDescription,
    membership
  } = data.payload
  if (fullname) payload.append('fullname', fullname)
  if (shortDescription) payload.append('shortDescription', shortDescription)
  if (richDescription) payload.append('richDescription', richDescription)
  if (email) payload.append('email', email)
  if (birthdate) payload.append('birthdate', birthdate)
  if (gender) payload.append('gender', gender)
  if (status) payload.append('status', status)
  if (membership !== undefined) {
    payload.append('membership', membership || '')
  }
  if (image) payload.append('image', image)
  if (verified !== undefined) payload.append('verified', verified.toString())
  if (available !== undefined) payload.append('available', available.toString())

  return {
    url: `/api/v1/users/${data.id}`,
    method: 'PUT',
    data: payload
  }
}

const createUser = (data: CreateUserData): AxiosRequestConfig => {
  return {
    url: `/api/v1/users`,
    method: 'POST',
    data: { ...data, gender: 'F' }
  }
}

const updateUserServices = (data: UpdateModelServicesData): AxiosRequestConfig => {
  return {
    url: `/api/v1/users/${data.userId}/services`,
    method: 'POST',
    data: data.payload
  }
}

const createModelImage = (data: CreateImageData): AxiosRequestConfig => {
  const payload = new FormData()
  payload.append('userId', data.id)
  payload.append('image', data.image)

  return {
    url: `/api/v1/users/${data.id}/images`,
    method: 'POST',
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
}

const updateModelImageAttributes = (data: UpdateModelImageAttributesData): AxiosRequestConfig => {
  return {
    url: `/api/v1/models/images/${data.id}`,
    method: 'PUT',
    data: data.payload
  }
}

const updateModelContact = (data: UpdateModelContactData): AxiosRequestConfig => {
  return {
    url: `/api/v1/models/${data.id}/contact`,
    method: 'POST',
    data: data.payload
  }
}

const getCountriesWithCities = (): AxiosRequestConfig => ({
  url: '/api/v1/countries',
  method: 'GET',
  params: { enabled: true }
})

const getProfileAttributes = (): AxiosRequestConfig => ({
  url: '/api/v1/profile-attributes',
  method: 'GET'
})

const getProfileAttributesByModel = (modelId: number): AxiosRequestConfig => ({
  url: `/api/v1/models/profile/${modelId}`,
  method: 'GET'
})

const updateOrCreateModelProfileAttributes = (
  data: UpdateProfileAttributeData
): AxiosRequestConfig => ({
  url: `/api/v1/models/profile/${data.userId}`,
  method: 'POST',
  data: data.payload
})

export {
  getUsers,
  getUserPollResult,
  updateUser,
  createUser,
  updateUserServices,
  createModelImage,
  updateModelImageAttributes,
  updateModelContact,
  getCountriesWithCities,
  getProfileAttributes,
  getProfileAttributesByModel,
  updateOrCreateModelProfileAttributes
}
