import { AxiosRequestConfig } from 'axios'

import { UpdateCountryData } from './types'

const getCountries = (): AxiosRequestConfig => ({
  url: '/api/v1/countries',
  method: 'GET'
})

const updateCountry = (payload: UpdateCountryData): AxiosRequestConfig => {
  const {
    id,
    payload: { active, enabled }
  } = payload

  return {
    url: `/api/v1/countries/${id}`,
    method: 'PUT',
    data: {
      active,
      enabled
    }
  }
}

const updateCountryCity = (data: any): AxiosRequestConfig => {
  return {
    url: `/api/v1/countries/cities/${data.id}`,
    method: 'PUT',
    data: {
      active: data.active
    }
  }
}

const createCountryCity = (data: any): AxiosRequestConfig => {
  return {
    url: `/api/v1/countries/cities/new`,
    method: 'POST',
    data: {
      name: data.name,
      countryId: data.countryId
    }
  }
}

export { getCountries, updateCountry, updateCountryCity, createCountryCity }
