import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { Country, UpdateCountryResponse, UpdateCountryData } from '../../types'
import { updateCountry } from '../../api'
// import { useStyles } from './styles'

interface UpdateCountryDialogProps {
  handleClose: () => void
  countryToUpdate: Country
  onDone: (newCountry: Country) => void
}

const UpdateCountryDialog: React.FC<UpdateCountryDialogProps> = ({
  handleClose,
  onDone,
  countryToUpdate
}) => {
  // const classes = useStyles()
  const [name] = useState(countryToUpdate.name)
  const [active, setActive] = useState(countryToUpdate.active)
  const [enabled, setEnabled] = useState(countryToUpdate.enabled)

  const [updateCountryApi, isLoading] = useApiCall<UpdateCountryData, UpdateCountryResponse>(
    updateCountry
  )

  const handleActiveChange: React.ChangeEventHandler<HTMLInputElement> = () => {
    setActive(!active)
  }

  const handleEnabledChange: React.ChangeEventHandler<HTMLInputElement> = () => {
    setEnabled(!enabled)
  }

  const onUpdateCountry = async () => {
    const country = {
      name,
      active,
      enabled
    }
    const payload = {
      id: countryToUpdate.id,
      payload: country
    }
    try {
      const { country: updatedCountry } = await updateCountryApi(payload)
      onDone(updatedCountry)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar pais. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Editar Pais"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onUpdateCountry}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox onChange={handleActiveChange} checked={active} />}
            label="Activo"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox onChange={handleEnabledChange} checked={enabled} />}
            label="Habilitado para backoffice"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateCountryDialog }
