import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { COLORS } from 'styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: 240,
    flexShrink: 0
  },
  drawerPaper: {
    width: 240
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,
  login: {
    marginLeft: 'auto'
  },
  link: {
    textDecoration: 'none',
    color: COLORS.ACCENT,
    fontStyle: '400'
  },
  activeLink: {
    textDecoration: 'none',
    color: COLORS.BASE,
    fontStyle: '400'
  }
}))

export { useStyles }
