import React from 'react'
import MaterialTable, { MaterialTableProps } from '@material-table/core'

const DefaultTable = <T extends object>(props: MaterialTableProps<T>) => {
  const { options, ...restProps } = props

  // const handlePageSizeChange = () => {
  //   setTimeout(() => {
  //     window.scrollTo({ top: 0, behavior: 'smooth' })
  //   }, 500)
  // }

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'Filas por página:'
          },
          toolbar: {
            nRowsSelected: '{0} filas(s) seleccionadas',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar'
          },
          header: {
            actions: 'Acciones'
          },
          body: {
            emptyDataSourceMessage: 'No hay nada todavía.',
            filterRow: {
              filterTooltip: 'Filtrar'
            }
          }
        }}
        options={{
          pageSize: 50,
          pageSizeOptions: [5, 10, 20, 50, 100],
          actionsColumnIndex: restProps.columns.length,
          ...options
        }}
        // onChangeRowsPerPage={handlePageSizeChange}
        {...restProps}
      />
    </div>
  )
}

export { DefaultTable }
