import React, { useEffect, useState, useContext, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import RoomServiceIcon from '@mui/icons-material/RoomService'
import FaceIcon from '@mui/icons-material/Face'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import { UserContext } from 'contexts'
import { snackbar } from 'components'
import { ERRORS } from 'consts'
import { useApi } from 'hooks'

import {
  CreateUserDialog,
  UpdateModelContactDialog,
  UpdateUserDialog,
  UpdateUserServicesOfferedDialog,
  UpdateUserProfileAttributesDialog
} from './components'
import { DefaultTable } from '../components'
import { getUsers } from './api'
import { GetModelsResponse, Model, User } from './types'

interface UserTableActionsProps {
  setIsCreateUserDialogOpen: Dispatch<SetStateAction<boolean>>
  setUserToUpdate: Dispatch<SetStateAction<User | null>>
  setIsUpdateUserDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsUpdateModelImageDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsUpdateUserServicesOfferedDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsUpdateUserProfileAttributesDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsDeleteUserDialogOpen: Dispatch<SetStateAction<boolean>>
  setUserToDelete: Dispatch<SetStateAction<User | null>>
  isAdmin: boolean
}

const Users: React.FC = () => {
  const navigate = useNavigate()
  const [{ data: models, isLoading }, refetchUsers] = useApi<GetModelsResponse, Model[]>(
    getUsers,
    (responseData: GetModelsResponse) => responseData?.models ?? [],
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) }
  )

  const { admin } = useContext(UserContext)
  const [isAdmin, setIsAdmin] = useState(false)

  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false)
  const [, setIsDeleteUserDialogOpen] = useState(false)
  const [, setUserToDelete] = useState<User | null>(null)
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false)
  const [isUpdateUserServicesOfferedDialogOpen, setIsUpdateUserServicesOfferedDialogOpen] =
    useState(false)
  const [isUpdateUserProfileAttributesDialogOpen, setIsUpdateUserProfileAttributesDialogOpen] =
    useState(false)
  const [isUpdateModelImageDialogOpen, setIsUpdateModelImageDialogOpen] = useState(false)
  const [userToUpdate, setUserToUpdate] = useState<User | null>(null)

  const UserTableActions = ({
    setIsCreateUserDialogOpen,
    setUserToUpdate,
    setIsUpdateUserDialogOpen,
    setIsUpdateModelImageDialogOpen,
    setIsUpdateUserServicesOfferedDialogOpen,
    setIsUpdateUserProfileAttributesDialogOpen,
    setIsDeleteUserDialogOpen,
    setUserToDelete,
    isAdmin
  }: UserTableActionsProps) => {
    return [
      {
        icon: 'add',
        tooltip: 'Nueva Modelo',
        isFreeAction: true,
        onClick: () => setIsCreateUserDialogOpen(true)
      },
      {
        icon: () => <EditIcon />,
        tooltip: 'Editar Modelo',
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          setUserToUpdate(row as User)
          setIsUpdateUserDialogOpen(true)
        }
      },
      {
        icon: () => <AddLocationIcon />,
        tooltip: 'Contacto/Ubicación',
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          setUserToUpdate(row as User)
          setIsUpdateModelImageDialogOpen(true)
        }
      },
      {
        icon: () => <RoomServiceIcon />,
        tooltip: 'Servicios ofrecidos',
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          setUserToUpdate(row as User)
          setIsUpdateUserServicesOfferedDialogOpen(true)
        }
      },
      {
        icon: () => <FaceIcon />,
        tooltip: 'Perfil',
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          setUserToUpdate(row as User)
          setIsUpdateUserProfileAttributesDialogOpen(true)
        }
      },
      {
        icon: () => <PhotoLibraryIcon />,
        tooltip: 'Imagenes',
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          const user = row as User
          navigate('/models/images', {
            state: { userId: user.topic, fullname: user.fullname }
          })
        }
      },
      {
        icon: 'delete',
        tooltip: `Eliminar`,
        onClick: (_: React.SyntheticEvent, row: User | User[]) => {
          setUserToDelete(row as User)
          setIsDeleteUserDialogOpen(true)
        },
        hidden: !!isAdmin
      }
    ]
  }

  useEffect(() => {
    if (admin === undefined) return
    const userHasAdminRole = admin.roles.find((rol) => rol.name === 'admin') !== undefined
    if (userHasAdminRole) setIsAdmin(true)
  }, [admin])

  const columns = [
    { title: 'ID', field: 'id' },
    { title: 'Nombre', field: 'fullname', sorting: true },
    {
      title: 'Telefono',
      render: (rowData: User) => rowData.contact?.phone || '',
      sorting: true
    },
    {
      title: 'Pais',
      render: (rowData: User) => rowData.contact?.country?.name || '',
      customSort: (a: User, b: User) => {
        const aCountry = a.contact?.country?.name || ''
        const bCountry = b.contact?.country?.name || ''
        return aCountry.localeCompare(bCountry)
      }
    },
    {
      title: 'Ciudad',
      render: (rowData: User) => rowData.contact?.city?.name || '',
      customSort: (a: User, b: User) => {
        const aCity = a.contact?.city?.name || ''
        const bCity = b.contact?.city?.name || ''
        return aCity.localeCompare(bCity)
      }
    },
    {
      title: 'Estado',
      render: (rowData: User) => rowData.status,
      sorting: true
    },
    {
      title: 'Disponible',
      render: (rowData: User) => (rowData.available ? 'Sí' : 'No'),
      sorting: true
    },
    {
      title: 'Verificación',
      render: (rowData: User) => (rowData.verified ? 'Sí' : 'No'),
      customSort: (a: User, b: User) => {
        if (a.verified === b.verified) {
          return 0
        }
        return a.verified ? -1 : 1
      }
    },
    {
      title: 'Membresia',
      render: (rowData: User) => rowData.membership ?? '',
      sorting: true
    }
  ]

  const actions = UserTableActions({
    setIsCreateUserDialogOpen,
    setUserToUpdate,
    setIsUpdateUserDialogOpen,
    setIsUpdateModelImageDialogOpen,
    setIsUpdateUserServicesOfferedDialogOpen,
    setIsUpdateUserProfileAttributesDialogOpen,
    setIsDeleteUserDialogOpen,
    setUserToDelete,
    isAdmin
  })

  const handleUpdateUserDialogClose = () => {
    setIsUpdateUserDialogOpen(false)
    setUserToUpdate(null)
  }

  const handleUpdateUserServicesOfferedDialogClose = () => {
    setIsUpdateUserServicesOfferedDialogOpen(false)
    setUserToUpdate(null)
  }

  const handleCreateUserDialogClose = () => {
    setIsCreateUserDialogOpen(false)
    window.location.reload()
  }

  const handleUpdateModelContactDialogClose = () => {
    setIsUpdateModelImageDialogOpen(false)
    setUserToUpdate(null)
    window.location.reload()
  }

  const handleUpdateUserDone = () => {
    refetchUsers()
    setIsUpdateUserDialogOpen(false)
  }

  return (
    <>
      <DefaultTable
        title="Modelos"
        columns={columns}
        data={models}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateUserDialogOpen && (
        <CreateUserDialog
          handleClose={() => setIsCreateUserDialogOpen(false)}
          onDone={handleCreateUserDialogClose}
        />
      )}
      {isUpdateUserDialogOpen && userToUpdate && (
        <UpdateUserDialog
          handleClose={handleUpdateUserDialogClose}
          userToUpdate={userToUpdate}
          onDone={handleUpdateUserDone}
        />
      )}
      {isUpdateUserServicesOfferedDialogOpen && userToUpdate && (
        <UpdateUserServicesOfferedDialog
          handleClose={handleUpdateUserServicesOfferedDialogClose}
          userToUpdate={userToUpdate}
        />
      )}
      {isUpdateModelImageDialogOpen && userToUpdate && (
        <UpdateModelContactDialog
          handleClose={handleUpdateModelContactDialogClose}
          userToUpdate={userToUpdate}
        />
      )}
      {isUpdateUserProfileAttributesDialogOpen && userToUpdate && (
        <UpdateUserProfileAttributesDialog
          handleClose={handleUpdateModelContactDialogClose}
          userToUpdate={userToUpdate}
        />
      )}
    </>
  )
}

export { Users }
