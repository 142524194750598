import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import moment from 'moment'

import { tokenLogin } from 'api'
import { snackbar, SnackbarContainer } from 'components'
import { UserContext } from 'contexts'
import { Admin } from 'features/Main/Admins/types'
import { useApiCall } from 'hooks'
import { COLORS } from 'styles'
import { TokenLoginResponse } from 'types'
import { setAuthHeader, removeAuthHeader } from 'utils'

import { Login } from './Login'
import { Main } from './Main'

moment.locale('es')

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.BASE,
      contrastText: COLORS.WHITE
    },
    secondary: {
      main: COLORS.ACCENT
    }
  }
})

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [admin, setAdmin] = useState<Admin | undefined>(() => {
    const localAdmin = localStorage.getItem('admin')
    if (!localAdmin) return undefined
    return JSON.parse(localAdmin)
  })

  const [tokenLoginApi] = useApiCall<void, TokenLoginResponse>(tokenLogin)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      setIsLoading(false)
      return
    }

    const updateAdmin = async () => {
      try {
        setAuthHeader(token)
        const { user: updatedAdmin } = await tokenLoginApi()
        setAdmin(updatedAdmin)
        localStorage.setItem('admin', JSON.stringify(updatedAdmin))
      } catch (error) {
        removeAuthHeader()
        setAdmin(undefined)
        localStorage.clear()
      } finally {
        setIsLoading(false)
      }
    }
    updateAdmin()
  }, [tokenLoginApi])

  // Memoizing the context value to avoid unnecessary re-renders
  const contextValue = useMemo(() => ({ admin, setAdmin }), [admin, setAdmin])

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <>
          <CssBaseline />
          {!isLoading && (
            <UserContext.Provider value={contextValue}>
              <Router>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Main />} />
                </Routes>
              </Router>
              <SnackbarContainer
                ref={(ref) => {
                  snackbar.containerInstance = ref
                }}
              />
            </UserContext.Provider>
          )}
        </>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export { App }
