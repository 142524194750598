import React from 'react'
import { NavLink } from 'react-router-dom'
import MaterialDrawer from '@mui/material/Drawer'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import SvgIcon from '@mui/material/SvgIcon'
import PersonIcon from '@mui/icons-material/Person'
import PublicIcon from '@mui/icons-material/Public'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import RoomServiceIcon from '@mui/icons-material/RoomService'

import { useStyles } from './styles'

interface Path {
  pathname: string
  search?: string
  hash?: string
  state?: object
}

interface Items {
  label: string
  link?: string | Path
  icon?: typeof SvgIcon
}

interface DrawerOption {
  label: string
  link?: string | Path
  icon: typeof SvgIcon
  items?: Items[]
}

const drawerOptions: DrawerOption[] = [
  {
    label: 'Modelos',
    link: '/models',
    icon: PersonIcon
  },
  {
    label: 'Paises',
    link: '/countries',
    icon: PublicIcon
  },
  {
    label: 'Servicios',
    link: {
      pathname: '/services'
    },
    icon: RoomServiceIcon
  },
  {
    label: 'Administradores',
    link: {
      pathname: '/admins'
    },
    icon: SupervisorAccountIcon
  }
]

const Drawer: React.FC = () => {
  const classes = useStyles()

  return (
    <MaterialDrawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.toolbar} />
      <List>
        {drawerOptions.map((option) => (
          <ListItem
            component={NavLink}
            to={option.link || ''}
            className={classes.link}
            key={option.label}
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </List>
    </MaterialDrawer>
  )
}

export { Drawer }
