import React from 'react'

import { UserContextType } from './types'

const UserContext = React.createContext<UserContextType>({
  admin: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAdmin: () => {}
})

export { UserContext }
