import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

import { Dialog, snackbar } from 'components'
import { StringUtils } from 'utils'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { STRINGS, ERRORS } from 'consts'
import { ErrorTypes } from 'types'
import { ApiError } from 'errors'

import { CreateAdminPayload, NewAdminFormData, Role, CreateAdminResponse } from '../../types'
import { createAdmin } from '../../api'
import { roleLabelsMap } from '../../constants'

const rules = {
  email: [
    {
      validate: (email: string) => StringUtils.isEmail(email),
      message: 'Debe ser un mail válido.'
    }
  ],
  password: [
    {
      validate: (password: string) => password && password.length >= STRINGS.PASSWORD_MIN_LENGTH,
      message: `La contraseña debe ser al menos de ${STRINGS.PASSWORD_MIN_LENGTH} caracteres.`
    }
  ],
  confirmPassword: [
    {
      validate: (confirmPassword: string, fields: Omit<NewAdminFormData, 'roles'>) =>
        confirmPassword && fields.password === confirmPassword,
      message: `Las contraseñas deben coincidir.`
    }
  ]
}

const getCreateAdminErrorMessage = (err: ApiError): string => {
  switch (err.type) {
    case ErrorTypes.EmailAddressInUse:
      return 'Este email ya se encuentra en uso'
    case ErrorTypes.ValidationError:
      return err.validationKeys?.includes('admin.email')
        ? 'Ingresar un email válido'
        : ERRORS.GENERIC_ERROR_MESSAGE
    default:
      return ERRORS.GENERIC_ERROR_MESSAGE
  }
}

interface NewAdminDialogProps {
  handleClose: () => void
  onDone: () => void
  roles: Role[]
}

const NewAdminDialog: React.FC<NewAdminDialogProps> = ({ handleClose, onDone, roles }) => {
  const [createAdminApi, isCreateAdminLoading] = useApiCall<
    CreateAdminPayload,
    CreateAdminResponse
  >(createAdmin)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [selectedRoles, setSelectedRoles] = useState<number[]>([])

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value)
  }

  const handleConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setConfirmPassword(e.currentTarget.value)
  }

  const handleRoleSelection =
    (id: number): React.ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setSelectedRoles(
        e.target.checked ? selectedRoles.concat(id) : selectedRoles.filter((val) => val !== id)
      )
    }

  const atLeastOneRoleSelected = selectedRoles.length > 0

  const fields = { email, password, confirmPassword }
  const { errors, hasErrors, isAnyFieldEmpty } = useCheckFormErrors(fields, rules)
  const isSubmitDisabled =
    hasErrors || isAnyFieldEmpty || isCreateAdminLoading || !atLeastOneRoleSelected

  const onCreateAdmin = async () => {
    try {
      await createAdminApi({
        email,
        password,
        roles: selectedRoles
      })
      onDone()
    } catch (err) {
      if (err instanceof ApiError) {
        snackbar.show(getCreateAdminErrorMessage(err))
      } else {
        snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      }
    }
  }

  return (
    <Dialog
      title="Nuevo administrador"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isCreateAdminLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateAdmin}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Email"
            placeholder="ejemplo@greeneat.com"
            value={email}
            fullWidth
            onChange={handleEmailChange}
            error={errors.email.hasError}
            helperText={errors.email.message}
            disabled={isCreateAdminLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contraseña"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={errors.password.hasError}
            helperText={errors.password.message}
            disabled={isCreateAdminLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Repetir contraseña"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={errors.confirmPassword.hasError}
            helperText={errors.confirmPassword.message}
            disabled={isCreateAdminLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup row>
            {roles.map((role) => (
              <FormControlLabel
                key={role.id}
                control={<Checkbox onChange={handleRoleSelection(role.id)} />}
                label={roleLabelsMap[role.name].label}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { NewAdminDialog }
