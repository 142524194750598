import { SnackbarContainer } from './SnackbarContainer'

interface SnackbarType {
  containerInstance: SnackbarContainer | null
  show: (messsage: string) => void
}

const snackbar: SnackbarType = {
  containerInstance: null,
  show: (message) => {
    if (snackbar.containerInstance) snackbar.containerInstance.showSnackbar(message)
  }
}

export { Snackbar } from './Snackbar'
export { ErrorSnackbar } from './ErrorSnackbar'
export { SnackbarContainer, snackbar }
