import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { ModelImage, UpdateModelImageAttributesData } from '../../types'
import { updateModelImageAttributes } from '../../api'

interface UpdateModelImageDialogProps {
  handleClose: () => void
  rowData: ModelImage
  onDone: () => void
}

const UpdateModelImageDialog: React.FC<UpdateModelImageDialogProps> = ({
  handleClose,
  rowData,
  onDone
}) => {
  const [isMain, setIsMain] = useState(rowData.isMain ?? false)
  const [show, setShow] = useState(rowData.show ?? false)

  const handlIsMainChange: React.ChangeEventHandler<HTMLInputElement> = async () => {
    await setIsMain(!isMain)
  }

  const handleShowChange: React.ChangeEventHandler<HTMLInputElement> = async () => {
    await setShow(!show)
  }

  const [updateUserApi, isLoading] = useApiCall<UpdateModelImageAttributesData, void>(
    updateModelImageAttributes
  )

  const onUpdateUser = async () => {
    const modelImage = {
      isMain,
      show
    }
    const payload = {
      id: rowData.id,
      payload: modelImage
    }
    try {
      await updateUserApi(payload)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar modelo. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Editar propiedades de imagen"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: false }}
      onAccept={onUpdateUser}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handlIsMainChange} checked={isMain} />}
            label="Imagen de perfil"
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleShowChange} checked={show} />}
            label="Mostrar"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateModelImageDialog }
