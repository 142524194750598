import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import moment, { Moment } from 'moment'
import { DatePicker } from '@mui/x-date-pickers'

import { Dialog, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'
import { STRINGS } from 'consts'

import { User, CreateUserData, CreateUserResponse } from '../../types'
import { createUser } from '../../api'

const rules = {
  fullname: [
    {
      validate: (value: string) => value.length <= 100,
      message: 'No puede superar los 100 caracteres'
    }
  ],
  email: [
    {
      validate: (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return value.length <= 254 && emailRegex.test(value)
      },
      message: 'Ingrese un correo electrónico válido'
    }
  ],
  birthdate: [
    {
      validate: (value: Moment | null) => {
        if (!value) {
          return false
        }
        return true

        /* const currentDate = moment();
        const minDate = currentDate.subtract(60, 'years').startOf('day');
        const maxDate = currentDate.subtract(18, 'years').endOf('day');

        return value.isSameOrAfter(minDate) && value.isSameOrBefore(maxDate); */
      },
      message: 'La fecha de nacimiento debe ser mayor a 18 años y menor a 60 años'
    }
  ]
}

const requiredFields = ['fullname', 'email']

interface CreateUserDialogProps {
  handleClose: () => void
  onDone: (newUser: User) => void
}

const CreateUserDialog: React.FC<CreateUserDialogProps> = ({ handleClose, onDone }) => {
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [birthdate, setBirthdate] = useState<Moment | null>(
    moment().subtract(18, 'years').startOf('day')
  )

  const [createUserApi, isLoading] = useApiCall<CreateUserData, CreateUserResponse>(createUser)

  const handleFullnameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFullname(e.target.value)
  }

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setBirthdate(newDate)
  }

  const fields = { fullname, email, birthdate }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onCreateUser = async () => {
    const user = {
      fullname,
      email,
      birthdate: birthdate?.toISOString()
    }

    try {
      const { user: newUser } = await createUserApi(user)
      onDone(newUser)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar modelo. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Crear modelo"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateUser}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Nombre fantasia"
            value={fullname}
            fullWidth
            onChange={handleFullnameChange}
            disabled={isLoading}
            required
            error={errors.fullname.hasError}
            helperText={errors.fullname.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={email}
            fullWidth
            onChange={handleEmailChange}
            disabled={isLoading}
            required
            error={errors.email.hasError}
            helperText={errors.email.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={birthdate}
            onChange={handleDateChange}
            minDate={moment().subtract(70, 'years')}
            maxDate={moment().subtract(18, 'years')}
            format={STRINGS.DATE_FORMAT}
            disabled={isLoading}
            label="Cumpleaños"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateUserDialog }
