import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { Country, City, UpdateOrCreateCountryCityResponse } from '../../types'
import { updateCountryCity, createCountryCity } from '../../api'

interface CreateUserPollDialogProps {
  handleClose: () => void
  countryToUpdate: Country
  onDone: (newCountry: Country) => void
}

const UpdateCountryCitiesDialog: React.FC<CreateUserPollDialogProps> = ({
  handleClose,
  countryToUpdate,
  onDone
}) => {
  const [countryCityToUpdate, setCountryCityToUpdate] = useState(countryToUpdate.cities ?? [])
  // const [currentCountryCity] = useState<City>()

  const [updateCountryCityApi] = useApiCall<any, UpdateOrCreateCountryCityResponse>(
    updateCountryCity
  )
  const isLoading = false
  const [createCountryCityApi] = useApiCall<any, UpdateOrCreateCountryCityResponse>(
    createCountryCity
  )

  // useEffect(() => {
  //   if (!currentCountryCity) return
  // }, [currentCountryCity])

  const handleChangeName = async (e: any, idx: number, item: City) => {
    if (item.id) {
      snackbar.show(`No está permitido modificar el nombre de una ciudad existente`)
      return
    }

    const newCountryCities = countryCityToUpdate.map((el, index) => {
      if (index === idx) {
        el.name = e.target.value
      }
      return el
    })
    setCountryCityToUpdate(newCountryCities)
  }

  const handleSaveNewCity = async (e: any, idx: number, item: City) => {
    const exists = countryCityToUpdate
      .filter((el) => el.id != null)
      .some((el) => item.name === el.name)
    if (exists) {
      snackbar.show(`Ya existe una ciudad con el nombre ${item.name}`)
      return
    }
    try {
      const response = await createCountryCityApi({ ...item })
      setCountryCityToUpdate(countryCityToUpdate.map((el) => (el.id === null ? response.city : el)))
    } catch (error) {
      if (error instanceof Error) {
        snackbar.show(error.message)
      } else {
        snackbar.show('An unexpected error occurred')
      }
    }
  }

  const handleChangeActive = async (e: any, item: City) => {
    const newCountryCities = countryCityToUpdate.map((el) => {
      if (item === el) {
        el.active = !el.active
      }
      return el
    })
    const newCountryCity = newCountryCities.find((el) => item.id === el.id)
    if (!newCountryCity) return
    setCountryCityToUpdate(newCountryCities)
    // setCountryCity(newCountryCity)
    const response = await updateCountryCityApi({ ...newCountryCity })
    console.log('response :>> ', response)
    // TODO
    if (response.city) onDone(countryToUpdate)
    /* setCountryCityToUpdate(countryCityToUpdate.map(el => (
      el.id === null ? response.city : el
    ))) */
  }

  const handleAddNewItem = () => {
    const existsEmpty = countryCityToUpdate.find((el) => el.name === '')
    if (existsEmpty) {
      snackbar.show(`Tiene un elemento sin completar aún`)
      return
    }

    const newItem = {
      id: null,
      name: '',
      active: false,
      countryId: countryToUpdate.id
    }
    setCountryCityToUpdate([...countryCityToUpdate, newItem])
  }

  // const handleRemoveItem = async (item: City) => {
  //   const countryCityToUpdateToDelete = countryCityToUpdate.find((el) => item.id === el.id)
  //   if (!countryCityToUpdateToDelete) return

  //   const newCountryCities = countryCityToUpdate.filter((el) => el != item)
  //   setCountryCityToUpdate(newCountryCities)

  //   console.log('TODO DELETE COUNTRY CITY')
  //   // await deleteUserPollApi(countryCityToUpdateToDelete)
  //   snackbar.show(`Respuesta eliminada`)
  // }

  const handleIsChecked = (active: boolean | null | undefined) => {
    if (!active) return false
    return true
  }

  return (
    <Dialog
      title={`Ciudades de ${countryToUpdate.name}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      readOnly
      contentStyle={{ minWidth: 600 }}
      style={{ minWidth: '60%' }}
      // cancelButtonText={"Cerrar"}
      // onAccept={saveCountryCity}
    >
      <Grid container spacing={3}>
        {countryCityToUpdate &&
          countryCityToUpdate.map((el, index) => (
            <React.Fragment key={`${el.id}_key`}>
              <Grid item lg={6} xs={6}>
                <TextField
                  label="Nombre"
                  value={el.name}
                  fullWidth
                  onChange={(ev) => handleChangeName(ev, index, el)}
                  disabled={isLoading || el.active}
                  id={`${el.id}`}
                />
              </Grid>
              {countryCityToUpdate.length > 0 && el.id !== null && (
                <Grid item xs={1} sm={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(ev) => handleChangeActive(ev, el)}
                        checked={handleIsChecked(el.active)}
                      />
                    }
                    label="Activar"
                  />
                </Grid>
              )}
              {!el.id && (
                <Grid item xs={1} sm={1}>
                  <Button onClick={(ev) => handleSaveNewCity(ev, index, el)}>
                    <SaveIcon />
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}
        {(countryCityToUpdate.length === 0 ||
          countryCityToUpdate.every((el) => el.id !== null)) && (
          <Grid item xs={12} sm={12}>
            <Button onClick={handleAddNewItem} variant="contained" color="secondary" autoFocus>
              Agregar ciudad
            </Button>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export { UpdateCountryCitiesDialog }
